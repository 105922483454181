import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { LeftPanel } from "./left-panel";
import CloseIcon from "../assets/icons/closeIcon.svg";

// Define the type for props
interface TemporaryDrawerProps {
  setOpenTemp: (open: boolean) => void; // Function to set the drawer open state
  openTemp: boolean; // Boolean to control if the drawer is open
  setSelectedProject: (open: boolean) => void;
  selectedProject: string;
}

const TemporaryDrawer: React.FC<TemporaryDrawerProps> = ({
  setOpenTemp,
  openTemp,
  setSelectedProject,
  selectedProject,
}) => {
  // Define the toggleDrawer function
  const toggleDrawer = (newOpen: boolean) => () => {
    setOpenTemp(newOpen);
  };

  // Drawer list items
  const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation">
      <List>
        {["Inbox", "Starred", "Send email", "Drafts"].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {["All mail", "Trash", "Spam"].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <div>
      <Drawer open={openTemp} onClose={() => setOpenTemp(false)} sx={{    minWidth: "365px"}}>
        <Box
          onClick={() => setOpenTemp(false)}
          sx={{ cursor: "pointer", textAlign: "end", padding: "10px" }}
        >
          <img src={CloseIcon} />
        </Box>
        <Box
          // sx={{ width: '80%' }}
          role="presentation"
          // onClick={toggleDrawer(false)}
        >
          <LeftPanel
            setOpenTemp={setOpenTemp}
            selectedProject={selectedProject}
            setSelectedProject={setSelectedProject}
          />
        </Box>
      </Drawer>
    </div>
  );
};

export default TemporaryDrawer;
