// LoadingScreen.js
import React from 'react';
import './LoadingScreen.css'; // Import the CSS for styling

const LoadingScreen = () => {
  return (
    <div className="loading-container">
      <div style={{display:"flex",columnGap:5}}>
        <span data-text="Loading" style={{color:" #cb6ce6"}}>Scanning 6 million US Patents</span>
        <div className='loading-text cool'>
        <span data-text=".">.</span>
        <span data-text=".">.</span>
        <span data-text=".">.</span>
        <span data-text=".">.</span>
       
        </div>
      </div>
    </div>
  );
};

export default LoadingScreen;
