import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  Navigate,
} from "react-router-dom";
import Login from "../components/pages/Login";
import Register from "../components/pages/Register";
import App from "../App";
import { GLOBAL_URL } from "../utils/constants";
import axios from "axios";
import { apiCall, setDefaultHeader } from "../utils/httpClient";
import Plans from "../components/planScreen";
import {
  NotificationManager,
  // @ts-ignore
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import { BookmarkProvider } from "../context/BookmarkContext";
import { MessageProvider } from "../context/MessageContext";
import toast from "react-hot-toast";

const AppRoutes = () => {
  const [isAuth, setIsAuth] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [Id, setId] = useState("");

  // const response = {
  //   data: {
  //     user: {
  //       id: "22",
  //       first_name: "vidit",
  //       last_name: "viionn",
  //       password: "e10adc3949ba59abbe56e057f20f883e",
  //       email: "vidit.viionn@gmail.com",
  //       created_at: "2024-09-09 01:21:57",
  //     },
  //     session: {
  //       id: "22",
  //       role: "user",
  //     },
  //   },
  // };

  useEffect(() => {
    const token = localStorage.getItem("userToken");
    if (token) {
      setIsAuth(true);
    }
  }, []);
  const submitData = (data) => {
    // localStorage.setItem('session_id', response.data.user.id);
    // localStorage.setItem('user', JSON.stringify(response.data));
    //   setIsAuth(true)
    //   navigate("/");
    setIsLoading(true);
    let params = {
      action: "user-login",
      email: data.email,
      password: data.password,
    };
    apiCall("post", GLOBAL_URL + "/api/login", params)
      .then(function (response) {
        console.log("🚀 ~ response:", response);
        if (response.status == 200) {
          const token = response.data.token;
          const user_id = response.data.data._id;
          toast.success("Logged in successfully")

          setDefaultHeader("token", token);
          localStorage.setItem("userToken", token);
          localStorage.setItem("user_id", user_id);
          localStorage.setItem("user", JSON.stringify(response.data.data));

          setIsAuth(true);
          if (Id) {
            navigate(`/plans?id=${Id}`);
          } else {
            navigate("/");
          }
        } else if (response.status == 201) {
          // setErrorMessage(response.data.error);
          // NotificationManager.error(response.data.message);
          toast.error(response?.data?.error)
        } else {
          NotificationManager.error("server error", "Error");
        }
      })
      .catch(function (error) {
        console.log(error);
        toast.error("Something went wrong. Try again.");
        setIsLoading(false);
      });
  };
  const RedirectRoute = ({ isAuth, children }) => {
    return isAuth ? <Navigate to="/" /> : children;
  };

  return (
    <MessageProvider>
      <BookmarkProvider>
        <Routes>
          <Route
            path="/login"
            element={
              <RedirectRoute isAuth={isAuth}>
                <Login submitData={submitData} setId={setId} />
              </RedirectRoute>
            }
          />
          <Route
            path="/register"
            element={
              <RedirectRoute isAuth={isAuth}>
                <Register />
              </RedirectRoute>
            }
          />

          <Route
            path="/"
            element={<App isAuth={isAuth} setIsAuth={setIsAuth} />}
          />
          <Route
            path="/c/:conversation_id"
            element={<App isAuth={isAuth} setIsAuth={setIsAuth} />}
          />
          <Route
            path="/plans"
            element={<Plans isAuth={isAuth} setIsAuth={setIsAuth} />}
          />
        </Routes>
      </BookmarkProvider>
    </MessageProvider>
  );
};

export default AppRoutes;
