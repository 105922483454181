import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState, useRef } from "react";
import SearchInput from "./SearchInput";
import Slider from "react-slick";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Properties } from "./properties";
import CloseIcon from "@mui/icons-material/Close";
import ChatWithPatent from "./ChatWithPatent";

const PatentDetailsModal = ({ results, detailsFor, handleClose }: any) => {
  console.log("🚀 ~ PatentDetailsModal ~ patentDetail:", results, detailsFor);
  const theme = useTheme(); // Get the theme object
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); //
  const [chatInput, setChatInput] = useState("");
  const sliderRef = useRef<Slider | null>(null);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: {
          xs: "90%", // 90% width on extra small screens
          sm: "80%", // 80% width on small screens
          md: "70%", // 70% width on medium screens
          lg: "60%", // 60% width on large screens
          xl: "60%", // 50% width on extra large screens
        },
        bgcolor: "background.paper",
        boxShadow: 24,
        overflowY: "auto",
        p: 4,
        maxHeight: "90vh",
        fontFamily: "inter",
      }}
    >
      <IconButton
        onClick={() => handleClose()}
        style={{
          zIndex: "99",
          position: "absolute",
          top: "10px",
          right: "10px",
        }}
      >
        <CloseIcon />
      </IconButton>
      <Typography variant="h6" fontWeight={700}>
        Patent Details
      </Typography>
      <Box mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            {/* <Box
              sx={{
                p: 2,
                borderRadius: 1,
                height: "100%",
              }}
            >
              <Box display={"flex"} gap={1}>
                <Box sx={{ width: "25%", fontWeight: "600" }}>Patent name</Box>
                <Box sx={{ width: "75%" }}>
                  CHIMERIC ANTIGEN RECEPTORS TARGETING CD79B AND CD19
                </Box>
              </Box>
              <Box display={"flex"} gap={1}>
                <Box sx={{ width: "25%", fontWeight: "600" }}>
                  Patent Number
                </Box>
                <Box sx={{ width: "75%" }}>US20220047636A1</Box>
              </Box>
              <Box display={"flex"} gap={1}>
                <Box sx={{ width: "25%", fontWeight: "600" }}>
                  Description :
                </Box>
                <Box sx={{ width: "75%" }}>
                  Described herein are upfront methods for treating a patient
                  suffering from a cancer. The method includes administering to
                  the patient a therapeutically effective amount of an
                  anti-cancer therapy including a chimeric antigen receptor
                  (CAR) including an extracellular domain including a
                  CD79b-binding domain and a CD19-binding domain, wherein the
                  patient has not been previously treated for the cancer. The
                  invention accordingly also relates to methods of producing and
                  utilizing, e.g., T cells including CARs having an
                  extracellular domain that binds CD79b and CD19.
                </Box>
              </Box>
              <Box display={"flex"} gap={1}>
                <Box sx={{ width: "25%", fontWeight: "600" }}>Owner</Box>
                <Box sx={{ width: "75%" }}>
                  The General Hospital Corporation
                </Box>
              </Box>
              <Box display={"flex"} gap={1}>
                <Box sx={{ width: "25%", fontWeight: "600" }}>Inventors</Box>
                <Box sx={{ width: "75%" }}>Marcela V. Maus</Box>
              </Box>
              <Box display={"flex"} gap={1}>
                <Box sx={{ width: "25%", fontWeight: "600" }}>CPC subclass</Box>
                <Box sx={{ width: "75%" }}>C07K</Box>
              </Box>
            </Box> */}
            {results?.length > 0 && (
              <Stack>
                <Properties
                  keyString="Patent name"
                  value={results[detailsFor]?.["title"] ?? "-"}
                />
                <Properties
                  keyString="Patent Number"
                  value={results[detailsFor]?.["id"] ?? "-"}
                />
                <Properties
                  keyString="Description"
                  value={results[detailsFor]?.["abstract"] ?? "-"}
                />
                <Properties
                  keyString="Owner"
                  value={results[detailsFor]?.["owner"] ?? "-"}
                />
                <Properties
                  keyString="Inventors"
                  value={results[detailsFor]?.["inventors"] ?? "-"}
                />
                <Properties
                  keyString="CPC subclass"
                  value={results[detailsFor]?.["index"] ?? "-"}
                />
                {results[detailsFor] && results[detailsFor].website && (
                  <Stack
                    style={{
                      justifyContent: "space-between",
                      flexDirection: "row",
                      gap: "20px",
                    }}
                  >
                    <Button
                      variant="outlined"
                      onClick={() => window.open(results[detailsFor].website)}
                      style={{ margin: "20px 0px 20px 0px", width: "100%" }}
                    >
                      Visit website
                    </Button>
                  </Stack>
                )}
              </Stack>
            )}

            {results?.result && (
              <Stack>
                <Properties
                  keyString="Patent name"
                  value={results.result.title ?? "-"}
                />
                <Properties
                  keyString="Patent Number"
                  value={results.result.id ?? "-"}
                />
                <Properties
                  keyString="Description"
                  value={results.result?.abstract ?? "-"}
                />
                <Properties
                  keyString="Owner"
                  value={results.result?.owner ?? "-"}
                />
                <Properties
                  keyString="Inventors"
                  value={results.result?.inventors ?? "-"}
                />
                <Properties
                  keyString="CPC subclass"
                  value={results.result?.index ?? "-"}
                />
                {results.result && results.result.website && (
                  <Stack
                    style={{
                      justifyContent: "space-between",
                      flexDirection: "row",
                      gap: "20px",
                    }}
                  >
                    <Button
                      variant="outlined"
                      onClick={() => window.open(results.result.website)}
                      style={{ margin: "20px 0px 20px 0px", width: "100%" }}
                    >
                      Visit website
                    </Button>
                  </Stack>
                )}
              </Stack>
            )}
          </Grid>

          {/* Box 2 */}
          <Grid item xs={12} sm={6}>
            {results?.result && (
              <img
                src={results?.result?.image}
                alt="Slide"
                width={"100%"}
                height={"250px"}
                style={{ objectFit: "cover" }}
              />
            )}
            {/* <Box
              sx={{
                // p: 2,
                borderRadius: 1,
                height: "100%", // Ensure both boxes have the same height
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                boxShadow: 1,
              }}
            > */}
            {/* <Typography variant="h6">Knowledge Graph</Typography> */}
            {results?.length > 0 && (
              <Box
                sx={{
                  maxWidth: 600,
                  margin: "0 auto",
                  padding: "20px",
                  position: "relative",
                }}
              >
                <Slider ref={sliderRef} {...settings}>
                  {/* Slide 1 */}
                  {results.map((result: any) => (
                    <Box key={result.id}>
                      <img
                        src={result.image}
                        alt="Slide"
                        width={"100%"}
                        height={"250px"}
                        style={{ objectFit: "cover" }}
                      />
                    </Box>
                  ))}
                </Slider>
                {/* Left Arrow */}
                <IconButton
                  onClick={() =>
                    sliderRef.current && sliderRef.current.slickPrev()
                  }
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "10px",
                    transform: "translateY(-50%)",
                    backgroundColor: "rgba(0, 0, 0, 0.5)", // Background color with opacity
                    color: "#fff",
                    "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.7)" },
                    height: "40px", // Explicit height
                    width: "40px", // Explicit width
                    display: "flex", // Ensures proper centering inside the button
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "50%",
                    minWidth: "40px",
                    padding: "6px 0px",
                    paddingLeft: "10px",
                  }}
                >
                  <ArrowBackIosIcon />
                </IconButton>

                {/* Right Arrow */}
                <IconButton
                  onClick={() =>
                    sliderRef.current && sliderRef.current.slickNext()
                  }
                  sx={{
                    position: "absolute",
                    top: "50%",
                    right: "10px",
                    transform: "translateY(-50%)",
                    backgroundColor: "rgba(0, 0, 0, 0.5)", // Background color with opacity
                    color: "#fff",
                    "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.7)" },
                  }}
                >
                  <ArrowForwardIosIcon />
                </IconButton>
              </Box>
            )}
            {/* </Box> */}
          </Grid>
        </Grid>
      </Box>
      <Box>
        {/* <SearchInput
          inputText={chatInput}
          setInputText={setChatInput}
          onSubmit={() => {}}
          isMobile={isMobile}
          placeHolder={"Start your chat"}
          buttonLabel={"Chat with patent"}
          showAttachFileIcon={false}
          btnDisable
        /> */}
         <ChatWithPatent
          currentUserId={"user1"}
          recipientId={"user2"}
        />
      </Box>
    </Box>
  );
};

export default PatentDetailsModal;
