import {
  Avatar,
  Box,
  Button,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { backgroundColor } from "../assets/colors";
import { Badge, IconButton } from "@mui/material";
import logo from "../assets/logo.png";
import leftLayout from "../assets/icons/layout-sidebar.svg";
import NotificationsIcon from "../assets/icons/notification.svg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { CheckCircle } from "@mui/icons-material";

const Header = ({ toggleDrawer, isAuth, notificationData }: any) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const notifications = 5;
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const [showNotification, setshowNotification] = useState(false);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = async () => {
    await localStorage.removeItem("userToken");
    await localStorage.removeItem("user_id");
    await localStorage.removeItem("user");
    navigate("/");
    window.location.reload();
  };
  const userDataString = localStorage.getItem("user");
  const userData = userDataString ? JSON.parse(userDataString) : null;
  // console.log(userData, "userdata");
  const name = userData ? userData.first_name : null;
  // const name =  null;
  return (
    <Stack
      direction="row"
      paddingX={"30px"}
      // paddingY={'20px'}
      // paddingBottom={'2px'}
      style={{ background: backgroundColor }}
      justifyContent={"space-between"}
      alignItems={"center"}
      // position={"fixed"}
      // width={"100%"}
      // zIndex={"10"}
      // top={"10px"}
    >
      {isMobile && isAuth && (
        <Box sx={{ height: "100%" }}>
          <Button sx={{}} onClick={toggleDrawer(true)}>
            <img src={leftLayout} alt="left-Layout" />
          </Button>
        </Box>
      )}
      <Box className="image-container">
        <img
          src={logo}
          style={{ cursor: "pointer", width: isMobile ? "110px" : "125px" }}
          onClick={() => navigate("/")}
        />
      </Box>

      {isAuth ? (
        <Stack direction={"row"} alignItems={"center"}>
          <IconButton
            aria-label="show new notifications"
            color="inherit"
            onClick={() => setshowNotification(!showNotification)}
            onBlur={() => setshowNotification(false)}
          >
            {/* <NotificationsIcon sx={{ position: "relative", fontSize: "34px" }} /> */}
            <img src={NotificationsIcon} style={{ width: "25px" }} />
            {notificationData?.length > 0 && (
              <Box
                sx={{
                  width: "13px",
                  height: "13px",
                  backgroundColor: "#F97066",
                  borderRadius: "50%",
                  position: "absolute",
                  top: 4,
                  right: 6,
                  border: "2px solid white",
                  marginBottom: "25px",
                }}
              ></Box>
            )}
          </IconButton>
          {showNotification && notificationData?.length > 0 ? (
            <Box
              sx={{
                backgroundColor: "white",
                width: 300,
                maxHeight: 300,
                position: "absolute",
                top: "82px",
                right: "85px",
                zIndex: 9,
                padding: 2,
                overflowY: "scroll",
                borderRadius: 5,
              }}
            >
              <Typography sx={{ margin: 1 }} fontWeight={600}>
                Notifications
              </Typography>
              {notificationData?.length > 0 &&
                notificationData.map((data: any) => (
                  <Paper
                    elevation={2}
                    sx={{
                      padding: 2,
                      display: "flex",
                      alignItems: "center",
                      mb: 1,
                    }}
                  >
                    <CheckCircle color="primary" sx={{ mr: 1 }} />
                    <Box>
                      <Typography variant="caption">{data.title}</Typography>
                      {/* <Typography variant="caption" color="text.secondary">
                    {time}
                  </Typography> */}
                    </Box>
                  </Paper>
                ))}
            </Box>
          ):showNotification && ( <Box
            sx={{
              backgroundColor: "white",
              width: 250,
              maxHeight: 300,
              position: "absolute",
              top: "82px",
              right: "85px",
              zIndex: 9,
              padding: 2,
              // overflowY: "scroll",
              borderRadius: 5,
              textAlign:"center"
            }}
          >
            <Typography sx={{ margin: 1}} fontWeight={600}>
                No Notifications 
              </Typography>
          </Box>
          )}
          <Tooltip title="Open settings">
            <IconButton onClick={handleOpenUserMenu}>
              <Avatar
                sx={{
                  width: 38,
                  height: 38,
                  backgroundColor: "black",
                  color: "white",
                  fontSize: "20px",
                  fontWeight: "bold",
                }}
              >
                {name ? name.charAt(0).toUpperCase() : ""}
              </Avatar>
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: "49px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <MenuItem
              sx={{ display: "flex", flexDirection: "column" }}
              onClick={() => {
                handleCloseUserMenu();
                navigate("/plans")
              }}
            >
              <Typography textAlign="center" onClick={() => navigate("/plans")}>
                UPGRADE
              </Typography>
            </MenuItem>
            <MenuItem
              sx={{ display: "flex", flexDirection: "column" }}
              onClick={() => {
                handleCloseUserMenu();
                handleLogout();
              }}
            >
              <Typography
                textAlign="center"
                style={{ width: "100%" }}
              >
                Logout
              </Typography>
            </MenuItem>
          </Menu>
        </Stack>
      ) : (
        <Button
          onClick={() => navigate("/plans")}
          variant="outlined"
          color="primary"
          sx={{
            // color: "#fff",
            borderRadius: 3,
            padding: 1.1, // 12px in MUI system
            // width: "100%",
            marginTop: 1, // 16px in MUI system
            fontWeight: 600,
          }}
        >
          UPGRADE
        </Button>
      )}
    </Stack>
  );
};

export default Header;
