import {
  TextField,
  IconButton,
  Paper,
  Typography,
  Stack,
  Skeleton,
  Box,
  Button,
  Card,
  CardContent,
  useMediaQuery,
  useTheme,
  Modal,
  Grid,
  Link,
} from "@mui/material";
import { writeFileXLSX, utils } from "xlsx";
import React, { useEffect, useRef, useState } from "react";
import { useSendMessage } from "../hooks/sendMessage";
import { SendSharp, AccountCircle, SmartToy } from "@mui/icons-material";
import { SampleCard } from "./sample-cards";
import { SearchRecommendations } from "./search-recommendations";
import { backgroundColor, rightPanelColor } from "../assets/colors";
import pinImg from "../assets/icons/pin.svg";
import startImg from "../assets/icons/start.svg";
import SearchInput from "./SearchInput";
import PatentDetailsModal from "./PatentDetailsModal";
import DownloadIcon from "@mui/icons-material/Download";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import CategoryChart from "./CategoryChart";
import PatentChart from "./PatentChart";
import resultLogo from "../assets/resultlogo.png";
import Cookies from "js-cookie";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { GLOBAL_URL } from "../utils/constants";
import LoadingScreen from "./LoadingScreen";
import { useNavigate, useParams } from "react-router-dom";
import { useBookmark } from "../context/BookmarkContext";
import { useContextMessages } from "../context/MessageContext";

export const RightPanel = ({
  isAuth,
  getNotification,
  selectedProject,
  setSelectedProject,
  opensidebar,
  isMobileResponsive,
}: any) => {
  console.log("🚀 ~ opensidebar:", opensidebar);
  const theme = useTheme(); // Get the theme object
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); //
  const [detailsFor, setDetailsFor] = useState(0);
  const [results, setResults] = useState<any[]>([]);
  console.log("results: ", results);
  const [inputText, setInputText] = useState("");

  const [isGraphVisible, setIsGraphVisible] = useState(false);
  const patentChartRef = useRef<HTMLDivElement>(null); // Ref for the first chart
  const categoryChartRef = useRef<HTMLDivElement>(null); // Ref for the second chart
  const [credits, setCredits] = useState(0);

  const [warning, setWarning] = useState(false);
  const [loginWarn, setLoginWarn] = useState(false);
  const [btnDisable, setBtnDisable] = useState<boolean>(false);
  const [aval_credits, setAval_credits] = useState(false);
  const [plan, setPlan] = useState(false);
  const [onlyCheck, setonlyCheck] = useState(true);
  const [graphLoading, setGraphLoading] = useState<boolean>(false);
  const userId = localStorage.getItem("user_id");
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { bookMark, setBookMark } = useBookmark();
  const { messagesContext, setMessageContext } = useContextMessages();
  const bottomRef = useRef<HTMLDivElement | null>(null);

  const handleMessageReceived = async (decrement?: boolean) => {
    if (!decrement) {
      setBtnDisable(false);
    } else {
      if (isAuth) {
        await handleGetRealCredits();
        setBtnDisable(false);
        getNotification();
      } else {
        const decrementCredits = credits - 1;
        const updatedData = { aval_cred: decrementCredits };
        Cookies.set("aval_cred", JSON.stringify(updatedData), {
          expires: 365 * 20,
        });
        // await handleGetRealCredits();
        setBtnDisable(false);
        // getNotification();
      }
    }
  };
  const { sendMessage, loading, messages, setMessages } = useSendMessage(
    handleMessageReceived
  );
  console.log("messages: ", messages);

  useEffect(() => {
    setonlyCheck(true);

    if (isAuth) {
      setWarning(false);

      const checkCreditsAndPlan = async () => {
        try {
          const [creditsResult] = await Promise.all([handleGetCredits()]);

          if (creditsResult.hasPlan || creditsResult.hasCredits) {
            setWarning(false);
            setLoginWarn(false);
          } else {
            setLoginWarn(true);
          }
        } catch (error) {
          setWarning(false);
        }
      };

      checkCreditsAndPlan();
    } else {
      const check = Cookies.get("aval_cred");
      if (check) {
        const myData = Cookies.get("aval_cred");
        if (myData) {
          const parsedData = JSON.parse(myData);
          const NewCredits = parsedData.aval_cred;

          if (NewCredits == 0) {
            setWarning(true);
          } else {
            // setBtnDisable(false);
          }
          setCredits(NewCredits);
        }
      } else {
        const credits = { aval_cred: 20 }; // add one credit
        const jsonString = JSON.stringify(credits);
        document.cookie = `aval_cred=${jsonString}; path=/; max-age=${
          60 * 60 * 24 * 365 * 20
        }`;
        setCredits(20);
      }
    }
  }, []);

  useEffect(() => {
    if (isAuth) setWarning(false);
  }, []);
  useEffect(() => {
    setWarning(false);
  }, [loginWarn]);

  const { conversation_id } = useParams();

  useEffect(() => {
    if (conversation_id) {
      getConversations(conversation_id);
    } else {
      setMessages([]);
      setMessageContext([]);
    }
  }, [conversation_id]);

  useEffect(() => {
    const message = messages[messages.length - 1];

    if (message?.results && message?.results.length) {
      setResults(message.results);
    }
  }, [messages.length]);

  useEffect(() => {
    if (messages?.length > 0) storeConversation(messages);
  }, [messages]);

  useEffect(() => {
    const chatEnd = document.getElementById("chart-end");
    // chatEnd && chatEnd.scrollIntoView();
  }, [messages.length]);

  const getConversations = async (conversation_id: any) => {
    const url = `${GLOBAL_URL}/api/conversation/getConversation`;
    const data = {
      conversation_id: conversation_id,
    };

    try {
      const response = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log("response: ", response);

      if (response.status === 200) {
        // const history: any =
        //   response?.data?.data?.length > 0
        //     ? response.data.chats.map((data: any) => {
        //         return {
        //           conversation_id: data.conversation_id,
        //           title: data.title,
        //         };
        //       })
        //     : [];
        // setSearchHistory(history);

        setMessages(response?.data?.data?.chats);
        setMessageContext(response?.data?.data?.chats);
        navigate(`/c/${conversation_id}`);
      } else {
        console.error("Failed to get projects:");
      }
    } catch (error) {
      console.error("Error in GetProject function:", error);
    }
  };

  const onSubmit = async () => {
    if (!btnDisable) {
      if (isAuth) {
        if (inputText) {
          const [creditsResult] = await Promise.all([handleGetCredits()]);

          if (creditsResult.hasPlan || creditsResult.hasCredits) {
            if (inputText.length > 0) {
              sendMessage(inputText, conversation_id);
              // setInputText("");
              setBtnDisable(true);
            }
          } else {
            setLoginWarn(true);
          }
        }
      } else {
        if (credits > 0) {
          const decrementCredits = credits - 1;
          if (inputText.length > 0) {
            setBtnDisable(true);
          }
          if (inputText.trim().length > 0) {
            sendMessage(inputText, conversation_id);
            setInputText("");
            setCredits(decrementCredits);
          }
        } else {
          setWarning(true);
          // setBtnDisable(true);
        }
      }
    }
  };

  const handleGetRealCredits = async () => {
    const user_id = await localStorage.getItem("user_id");
    let params = {
      _id: user_id,
    };

    try {
      const response = await axios.post(
        `${GLOBAL_URL}/api/checkAvtCretits`,
        params
      );
      if (response.status === 200) {
        return { hasPlan: true, hasCredits: false };
      } else if (response.status === 201) {
        setPlan(false);
        const data = response.data.avl_credits;

        setAval_credits(true);
        return { hasPlan: false, hasCredits: true };
      } else {
        return { hasPlan: false, hasCredits: false };
      }
    } catch (error) {
      return { hasPlan: false, hasCredits: false };
    }
  };

  const handleGetCredits = async () => {
    const user_id = await localStorage.getItem("user_id");
    let params = {
      _id: user_id,
      onlyCheck: onlyCheck,
    };

    try {
      const response = await axios.post(
        `${GLOBAL_URL}/api/checkAvtCretits`,
        params
      );
      if (response.status === 200) {
        return { hasPlan: true, hasCredits: false };
      } else if (response.status === 202) {
        return { hasPlan: false, hasCredits: true };
      } else if (response.status === 400) {
        return { hasPlan: false, hasCredits: false };
      } else if (response.status === 201) {
        setPlan(false);
        const data = response.data.avl_credits;

        setAval_credits(true);
        return { hasPlan: false, hasCredits: true };
      } else {
        return { hasPlan: false, hasCredits: false };
      }
    } catch (error) {
      return { hasPlan: false, hasCredits: false };
    }
  };

  const toggleViewGraph = () => {
    setIsGraphVisible(!isGraphVisible);
  };
  // open patent details modal

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleExport = (RESULTS: any) => {
    // Prepare the data for the Excel sheet
    const worksheet = utils.json_to_sheet(RESULTS);

    // Create a new workbook
    const workbook = utils.book_new();

    // Add the worksheet to the workbook
    utils.book_append_sheet(workbook, worksheet, "Results");

    // Save the workbook as an Excel file
    writeFileXLSX(workbook, "results.xlsx");
  };

  const downloadChartAsPDF = async () => {
    setGraphLoading(true);
    const pdf = new jsPDF("p", "pt", "a4");

    if (patentChartRef.current && categoryChartRef.current) {
      // Capture the first chart
      const canvas1 = await html2canvas(patentChartRef.current);
      const imgData1 = canvas1.toDataURL("image/png");
      pdf.addImage(imgData1, "PNG", 20, 20, 550, 500); // pdf.addImage(imageData, format, x, y, width, height);
      // Capture the second chart
      const canvas2 = await html2canvas(categoryChartRef.current);
      const imgData2 = canvas2.toDataURL("image/png");
      pdf.addPage(); // Add a new page for the second chart
      pdf.addImage(imgData2, "PNG", 20, 20, 550, 500);
    }
    pdf.save("charts.pdf");
    setGraphLoading(false);
  };
  const highlight = [
    "US9904892B2",
    "This is a second key highlight for the patents.",
    "This is a third key highlight for the patents.",
  ];

  const handleDowloanPDF = async () => {
    const params = await {
      patents: results,
      highlight: highlight,
    };
    try {
      // Make a fetch request to the backend to get the PDF
      const response = await fetch(`${GLOBAL_URL}/api/pdf/generate-pdf`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Optional, mainly for server-side info
        },
        body: JSON.stringify(params),
      });

      // Check if the response is OK
      if (!response.ok) {
        throw new Error("Failed to download PDF");
      }

      // Convert the response to a blob
      const blob = await response.blob();

      // Create a URL for the blob and set it as the href of a link element
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;

      // Set the filename for the downloaded PDF
      link.setAttribute("download", "download.pdf");

      // Append the link to the body, click it and then remove it
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Optionally, revoke the object URL to free up memory
      window.URL.revokeObjectURL(url);
    } catch (error) {
      alert("There was an error downloading the PDF.");
    }
  };

  const storeConversation = async (messages: any) => {
    try {
      const data: object = {
        conversation_id: conversation_id,
        chats: messages,
        title: inputText,
        project_id: selectedProject,
        user_id: userId,
      };
      const response = await axios.post(
        `${GLOBAL_URL}/api/conversation/storeConversation`,
        data
      );
      console.log("response: ", response);
      if (response.status === 200) {
        console.log("Stored chats successfull: ");
        setInputText("");
      } else {
        console.log("error in crateConversationId");
      }
    } catch (error) {
      return { hasPlan: false, hasCredits: false };
    }
  };

  const scrollToBottom = () => {
    // if (bottomRef.current) {
    //   bottomRef.current.({ behavior: "smooth" });
    // }
    setTimeout(() => {

      if (bottomRef.current) {
        bottomRef.current.scrollTop = bottomRef.current.scrollHeight + 200;
      }
    },500)
    // window.scroll(0, 0)
  };
  return (
    <Paper
      sx={{
        width: "90%",
        height: "90%",
        borderRadius: "20px",
        backgroundColor: rightPanelColor,
        boxShadow: "none",
        // marginTop:'-55px'
      }}
    >
      <Box
        sx={{
          textAlign: "center",
          fontSize: "34px",
          fontWeight: "800",
          color: "#343435",
        }}
      >
        How may I assist you?
      </Box>

      <Stack
        direction={"column"}
        width="100%"
        height={"100vh"}
        borderRadius="20px"
        padding={"20px"}
        style={{
          background: rightPanelColor,
          overflow: "auto",
          paddingBottom: "280px",
        }}
        spacing="20px"
        

      >
        <Stack
          direction={"column"}
          spacing="20px"
          id="chat-containor"
          style={{ flex: 1, background: rightPanelColor, overflowY: "auto" }}
      ref={bottomRef}

        >
          {messages.map((message, idx) => (
            <Stack
              direction="column"
              key={idx}
              spacing="10px"
              paddingLeft={1}
              paddingRight={1}
            >
              <Stack
                direction="row"
                spacing="10px"
                justifyContent={"start"}
                alignItems="end"
              >
                {message.isResponse ? (
                  <img src={resultLogo} width={80} height={20} />
                ) : (
                  <AccountCircle />
                )}
                <Typography variant="body1" fontWeight={"bold"} >
                  {message.isResponse ? "" : "You"}
                </Typography>
                <Typography variant="subtitle2">
                  {/* {message?.time?.toLocaleTimeString()} */}
                </Typography>
              </Stack>
              <Typography variant="body1">{message.message}</Typography>
              {results?.length > 0 && (
                <Box display={"flex"} gap={2}>
                  {message.isResponse ? (
                    <>
                      <Button
                        sx={{ alignSelf: "flex-start" }}
                        onClick={() => toggleViewGraph()}
                        className="my-link"
                      >
                        <Link underline="hover">View Graph</Link>
                      </Button>
                      {/* {/ <h5 onClick={() => handleDowloanPDF()} style={{cursor: "pointer", textDecoration: "underline", color: "blue"}}>Download full patent report</h5> /} */}

                      <Modal
                        open={isGraphVisible}
                        onClose={() => setIsGraphVisible(false)}
                        disablePortal={true}
                      >
                        <Stack
                          direction={"column"}
                          spacing="5px"
                          borderRadius={"20px"}
                          padding={"20px"}
                          style={{
                            // maxWidth: "1200px",
                            top: "50%",
                            left: "50%",
                            position: "absolute",
                            transform: "translate(-50%, -50%)",
                            background: "white",
                          }}
                        >
                          <IconButton
                            onClick={() => setIsGraphVisible(false)}
                            style={{
                              zIndex: "99",
                              position: "absolute",
                              top: "10px",
                              right: "10px",
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                          <Grid
                            display={"flex"}
                            justifyContent={"center"}
                            flexDirection={"column"}
                          >
                            <Grid style={{ display: "flex" }}>
                              <PatentChart
                                results={results}
                                patentChartRef={patentChartRef}
                                categoryChartRef={categoryChartRef}
                              />
                              <CategoryChart
                                results={results}
                                categoryChartRef={categoryChartRef}
                              />
                            </Grid>
                            <Button
                              variant="outlined"
                              onClick={downloadChartAsPDF}
                              sx={{
                                color: "rgb(98 203 255)",
                                borderColor: "rgb(98 203 255)",
                              }}
                            >
                              {graphLoading
                                ? "Preparing for Download...."
                                : "Download Graph"}
                            </Button>
                          </Grid>
                        </Stack>
                      </Modal>
                    </>
                  ) : (
                    ""
                  )}
                  {message.isResponse && (
                    <Button
                      sx={{ alignSelf: "flex-start" }}
                      onClick={() => handleExport(message.results)}
                    >
                      <Link underline="hover">Download Report</Link>
                    </Button>
                  )}
                  {message.isResponse && (
                    <Button
                      sx={{ alignSelf: "flex-start" }}
                      onClick={() => handleDowloanPDF()}
                    >
                      <Link underline="hover">Download pdf</Link>
                    </Button>
                  )}
                </Box>
              )}
              {message?.results && message?.results.length > 0 && (
                <Stack
                  width={"100%"}
                  direction={"row"}
                  flexWrap={"wrap"}
                  gap={"20px"}
                  // rowGap={"5px"}
                  // spacing="15px"
                  padding="20px"
                  // style={{ overflowX: "auto" }}
                  // height={"450px"}
                  paddingBottom="50px"
                >
                  {/* {message.results.map((result, idx) => (
                    <SampleCard
                      selectedProject={selectedProject}
                      handleClickOpen={handleClickOpen}
                      key={idx}
                      result={result}
                      setResultsFor={setDetailsFor}
                      resultPosition={idx}
                    />
                  ))} */}
                  {message.results.map((result, idx) => {
                    const bookmarkedPatentNumbers = new Set(
                      bookMark.map((bookmark: any) => bookmark.patent_no)
                    );

                    const isBookmarked = bookmarkedPatentNumbers?.has(
                      result.publication_id
                    );
                    return (
                      <SampleCard
                        selectedProject={selectedProject}
                        handleClickOpen={handleClickOpen}
                        key={idx}
                        result={result}
                        setResultsFor={setDetailsFor}
                        resultPosition={idx}
                        isBookmarked={!isBookmarked}
                        isAuth={isAuth}
                        // bookMarkAdded={bookMark?.length > 0 ? bookMark?.find((item: any) => item.patent_no === result.id) : false}
                      />
                    );
                  })}
                </Stack>
              )}
            </Stack>
          ))}
          <div id="chart-end"></div>
          {loading && (
            <>
              {" "}
              <Stack
                direction="row"
                spacing="10px"
                justifyContent={"start"}
                alignItems="end"
              >
                {/* <SmartToy />
              <Typography variant="body1" fontWeight={"bold"}>
                GetPatent
              </Typography>
              <Skeleton>
                <Typography variant="body1" fontWeight={"bold"}>
                  Fetching response
                </Typography>
              </Skeleton> */}
                <img src={resultLogo} height={20} width={80} />
              </Stack>
              <Typography alignSelf={"flex-start"}>
                {/* Searching for patents... */}
                <LoadingScreen />
              </Typography>
            </>
          )}
          <SearchInput
            inputText={inputText}
            setInputText={setInputText}
            onSubmit={() => {
              onSubmit();
            }}
            isMobile={isMobile}
            placeHolder={"Ask GetPatent!"}
            buttonLabel={"Send"}
            showAttachFileIcon={true}
            btnDisable={btnDisable}
            messages={messages}
            opensidebar={opensidebar}
            isMobileResponsive={isMobileResponsive}
            openmodalstate={open}
            isAuth={isAuth}
            scrollToBottom={scrollToBottom}
          />

          {warning ? (
            <p style={{ color: "red", textAlign: "center" }}>
              Your free credit quota has been expired. Please Register your
              account to get more credits.
            </p>
          ) : (
            ""
          )}

          {loginWarn ? (
            <p style={{ color: "red", textAlign: "center" }}>
              Your free credit quota has been expired. Please
              <Button
                onClick={() => navigate("/plans")}
                variant="text"
                sx={{
                  textTransform: "none",
                  fontSize: "1rem",
                  "&:hover": {
                    textDecoration: "underline", // Keep underline on hover
                  },
                }}
              >
                UPGRADE
              </Button>
            </p>
          ) : (
            ""
          )}
        </Stack>
        {/* Patent Details Modal */}
        <Modal open={open} onClose={handleClose}>
          <PatentDetailsModal
            results={results}
            detailsFor={detailsFor}
            handleClose={handleClose}
          />
        </Modal>
      </Stack>
    </Paper>
  );
};
