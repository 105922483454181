import {
  Box,
  Button,
  Card,
  FormControlLabel,
  IconButton,
  Modal,
  Paper,
  Radio,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  customBlack,
  lightGray,
  serviceDescriptionCards,
} from "../assets/colors";
import plusIcon from "../assets/icons/plus-square.svg";
import savedIcon from "../assets/icons/bookmark.svg";
import newchatIcon from "../assets/icons/newChat.svg";
import searchIcon from "../assets/icons/search.svg";
import ProjectModal from "./emailAddModal";
import CloseIcon from "@mui/icons-material/Close";
import quesMark from "../assets/icons/ques-mark.svg";
import MoreVertSharpIcon from "@mui/icons-material/MoreVertSharp";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";
import { useGetBookmark } from "../hooks/getBookmarks";
import { GLOBAL_URL } from "../utils/constants";
import { useBookmark } from "../context/BookmarkContext";
import { useNavigate, useParams } from "react-router-dom";
import { useSendMessage } from "../hooks/sendMessage";
import { useContextMessages } from "../context/MessageContext";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import LibraryAddRoundedIcon from "@mui/icons-material/LibraryAddRounded";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import PatentDetailsModal from "./PatentDetailsModal";
import toast from "react-hot-toast";
import ConfirmationModal from "./confirmationModal";

// import useBookmark from "../hooks/crateBookmark";

interface LeftPanelProps {
  setOpenTemp: (open: boolean) => void; // Function to set the drawer open state
  selectedProject: any; // or define a more specific type if you know the structure
  setSelectedProject: (open: any) => void; // same as above, can be more specific
}

const projects = [
  { id: "1", name: "Project one " },
  { id: "2", name: "Project two" },
];

const descriptions = [
  "A handheld device that combines mobile phone capabilities with computing functions.",
  "A portable personal computer with a clamshell form factor, suitable for mobile use.",
  "An appliance used to brew coffee by forcing hot water through ground coffee beans.",
  "A small light fixture designed to illuminate a workspace or desk area.",
  "A camera that captures photographs in digital format rather than on film.",
];

const searchHistory = [
  "An appliance used...",
  "A camera that captures...",
  "A handheld device that...",
  "A small light fixture...",
  "A portable personal computer...",
];

export const LeftPanel: React.FC<LeftPanelProps> = ({
  setOpenTemp,
  selectedProject,
  setSelectedProject,
}) => {
  console.log("🚀 ~ selectedProject: llll", selectedProject);
  // Tips panel state
  const navigate = useNavigate();
  const { conversation_id } = useParams();
  const [showTip, setshowTip] = useState(true);
  const [projects, setProjects] = useState<any>();

  const [userEmail, setUserEmail] = useState();
  const [userId, setUserId] = useState();
  const [bookmarkId, setBookMarkId] = useState<any>();
  const [selectedConvoId, setSelectedConvoId] = useState<any>();
  const { bookMark, setBookMark } = useBookmark();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [bookmarkanchorEl, setbookmarkAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const [deletedItem, setDeletedItem] = React.useState<any>({});
  const openOption = Boolean(anchorEl);
  const bookmarkopenOption = Boolean(bookmarkanchorEl);
  const [open, setOpen] = useState<boolean>(false);
  const [searchHistory, setSearchHistory] = useState([]);
  const [searchHistoryMenu, setsearchHistoryMenu] = React.useState<any>([]);
  console.log("🚀 ~ searchHistoryMenu:", searchHistoryMenu);
  const [editEmail, setEditEmail] = useState<any>({
    open: false,
    editData: {},
  });

  const { setMessages, messages } = useSendMessage(() => {});
  const { messagesContext, setMessageContext } = useContextMessages();
  const [showProjectMenuOption, setshowProjectMenuOption] =
    React.useState<any>("");
  const [openPatentModal, setopenPatentModal] = useState(false);
  const [modalPatentDetail, setmodalPatentDetail] = React.useState<any>([]);
  const handleClosePatentModal = () => setopenPatentModal(false);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [confirmModalMsg, setConfirmModalMsg] = React.useState<any>({});

  console.log("🚀 ~ showProjectMenuOption:", showProjectMenuOption);
  console.log("messages: lp", messages);
  console.log("searchHistory: ", searchHistory);

  console.log("bookMark: left pannel", bookMark);

  useEffect(() => {
    const user: any = JSON.parse(localStorage.getItem("user") || "null");
    if (user) {
      setUserEmail(user.email);
      setUserId(user._id);
    }
    const projectId = localStorage.getItem("projectId");
    if (projectId) {
      console.log(
        "🚀 ~ useEffect ~ projectId: kkk",
        typeof projectId,
        projectId
      );
      setSelectedProject(projectId === "null" ? null : projectId);
    } else {
      setSelectedProject(null);
    }
  }, []);

  useEffect(() => {
    if (userEmail && userId) {
      GetProject();
    }
  }, [userEmail, userId]);

  useEffect(() => {
    getHistory();
    getBookmark();
  }, [selectedProject]);

  useEffect(() => {
    getHistory();
  }, [messagesContext?.length]);

  const GetProject = async () => {
    console.log("Starting GetProject function");
    const url = `${GLOBAL_URL}/api/project/getProject`;

    const projectData = {
      userEmail: userEmail,
      user_id: userId,
    };

    try {
      const response = await axios.post(url, projectData, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response) {
        const data = await response.data;

        if (data?.length > 0) {
          setProjects(data[0]?.project);
          localStorage.getItem("projectId");
        } else {
          localStorage.setItem("projectId", "null");
          setSelectedProject(null);
        }
      } else {
        console.error("Failed to get projects:");
      }
    } catch (error) {
      console.error("Error in GetProject function:", error);
    }
  };

  const handleRadioChange = (projectId: any) => {
    setSelectedProject(projectId);
    localStorage.setItem("projectId", projectId);
    navigate("/");
  };

  // const handleClick = (
  //   event: React.MouseEvent<HTMLButtonElement>,
  //   item: any,
  //   index: any
  // ) => {
  //   setAnchorEl(event.currentTarget);
  //   setDeletedItem(item);
  // };

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    item: any,
    index: any
  ) => {
    setbookmarkAnchorEl(event.currentTarget);
    setDeletedItem(item);
    setBookMarkId(item.bookmark_id); // Ensure bookmarkId is updated
    console.log("item.patent_no: ", item.patent_no);
  };

  const handleHistoryMenuClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    item: any,
    index: any
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedConvoId(item.conversation_id); // Ensure bookmarkId is updated
    console.log("item.patent_no: ", item);
  };

  const handleCloseOption = (bookmarkid: any) => {
    setbookmarkAnchorEl(null);
    setBookMarkId(bookmarkid);
    console.log(bookmarkId);
  };

  const handleOpen = (e: any) => {
    setOpen(true);
    // setOpenTemp(false);
  };

  const handleClose = async (e: any) => {
    // e.stopPropagation();
    setAnchorEl(null);
    setOpen(false);
    setEditEmail({
      open: false,
      editData: {},
    });

    // await handleDeleteHistory(searchHistoryMenu?.conversation_id);
  };
  const handleBookmark = async (disc: any) => {
    console.log("disc: ", disc);
    try {
      const url = `${GLOBAL_URL}/api/bookmark/createBookmark`;
      const bookMarkData: any = {
        projectId: selectedProject ?? "",
        user_id: userId,
        result: disc?.result,
        isBookmark: false,
        patent_no: disc?.patent_no,
      };
      console.log("bookMarkData: ", bookMarkData);
      try {
        const response = await axios.post(url, bookMarkData, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (response.status === 200) {
          console.log("Bookmark successfully updated");
          toast.success(response?.data?.message)
          getBookmark();
        } else {
          getBookmark();
          console.log("Error : mm");
          // throw new Error("Network response was not ok");
        }
      } catch (err: any) {
        console.error("Error adding or removing bookmark:", err.message);
        // setError(err.message);
      } finally {
        // setLoading(false);
        getBookmark();
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const handleDeleteHistory = async (disc: any) => {
    try {
      const url = `${GLOBAL_URL}/api/bookmark/deleteSearchHistory`;
      const data: any = {
        conversation_id: disc,
      };
      try {
        const response = await axios.post(url, data, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (response.status === 200) {
          console.log("🚀 ~ handleDeleteHistory ~ response:", response)
          console.log("History deleted successfully!");
          toast.success(response?.data?.message)
          getHistory();
          setAnchorEl(null);
        } else {
          getHistory();
          // throw new Error("Network response was not ok");
        }
      } catch (err: any) {
        console.error("Error adding or removing bookmark:", err.message);
        // setError(err.message);
      } finally {
        // setLoading(false);
        getHistory();
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const getBookmark = async () => {
    const user_id = await localStorage.getItem("user_id");
    const bookmarkData = {
      user_id: user_id,
      projectId: selectedProject ?? "",
    };

    try {
      const response = await axios.post(
        `${GLOBAL_URL}/api/bookmark/getBookmark`,
        bookmarkData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response, "iioo");

      if (response && response.data) {
        const fetchedBookmarks = response.data;
        // setBookmarks(fetchedBookmarks);
        setBookMark(fetchedBookmarks);
      } else {
        console.error("Failed to retrieve bookmarks.");
      }
    } catch (error) {
      console.log("errrrrrrrrrrrrr");
      setBookMark([]);
      console.error("Error rrr in getBookmark function:", error);
    } finally {
      // setLoading(false);
    }
  };

  const getHistory = async () => {
    const data = {
      project_id: selectedProject ?? "",
      user_id: JSON.parse(localStorage.getItem("user") || "null")?._id,
    };

    try {
      const response = await axios.post(
        `${GLOBAL_URL}/api/conversation/getSearchHistory`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response && response.data) {
        const fetchHistory = response.data.data;
        setSearchHistory(fetchHistory);
      } else {
        console.error("Failed to retrieve bookmarks.");
      }
    } catch (error) {
      console.log("errrrrrrrrrrrrr");
      setBookMark([]);
      console.error("Error rrr in getBookmark function:", error);
    } finally {
      // setLoading(false);
    }
  };

  const handleDelete = async (project: any) => {
    console.log(project, "project");
    const data = {
      projectId: project.projectId,
    };

    try {
      const response = await axios.post(
        `${GLOBAL_URL}/api/project/deleteProject`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response) {
        console.log("🚀 ~ handleDelete ~ response:", response);
        toast.success(response?.data?.message);
        GetProject();
        handleRadioChange(null);
      } else {
        console.error("Failed to retrieve bookmarks.");
      }
    } catch (error) {
      setBookMark([]);
      console.error("Error rrr in getBookmark function:", error);
    } finally {
      // setLoading(false);
    }
  };
  function capitalizeFirstLetter(str: any) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const handleConfirm = (value: any) => {
    console.log("🚀 ~ handleConfirm ~ value:", value);
    console.log("Action confirmed");
    if (value?.title === "Delete Project") {
      handleDelete(value?.project);
    } else if (value?.title === "Delete History") {
      handleDeleteHistory(value?.history);
    } else if (value?.title === "Delete Bookmark") {
      handleBookmark(value?.desc);
    }
    setOpenConfirmModal(false);
  };

  const handleOpenModal = () => {
    setOpenConfirmModal(true);
  };

  const handleCloseModal = () => {
    setOpenConfirmModal(false);
  };
  return (
    <Stack
      direction="column"
      spacing="20px"
      paddingLeft={"20px"}
      paddingRight="20px"
      // paddingTop={"110px"}
      // className="sidebar"
    >
      <Stack
        className="leftCart"
        style={{
          background: serviceDescriptionCards,
        }}
        direction={"row"}
      >
        <Box width={"100%"}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "-10px",
            }}
          >
            <Typography color={customBlack} fontWeight="600" fontSize={"18px"}>
              Project
            </Typography>
            <Tooltip title="Add New Project">
              <Button
                onClick={handleOpen}
                sx={{
                  borderRadius: "50%",
                  width: "40px",
                  height: "40px",
                  minWidth: "auto",
                  boxShadow: "none",
                }}
              >
                <img src={plusIcon} alt="bounce-rate-icon" />
              </Button>
            </Tooltip>
          </Box>
          <Box
            // sx={{
            //   height: "85px",
            //   overflowY: "auto",
            //   boxSizing: "border-box",
            //   borderWidth: "1px",
            //   borderColor: "#ccc",
            //   scrollbarWidth: "thin",
            // }}

            sx={{
              height: "105px",
              overflowY: "auto",
              boxSizing: "border-box",
              borderWidth: "1px",
              borderColor: "#ccc",
              scrollbarWidth: "none", // Hide scrollbar by default for Firefox
              "&:hover": {
                scrollbarWidth: "thin", // Show scrollbar on hover for Firefox
                "&::-webkit-scrollbar": {
                  width: "5px", // Show thin scrollbar on hover for WebKit browsers
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#ccc", // Customize scrollbar thumb color on hover
                  borderRadius: "5px",
                },
              },
              "&::-webkit-scrollbar": {
                width: "0px", // Initially hide scrollbar for WebKit browsers
              },
            }}
          >
            <Box
              onMouseOver={() => setshowProjectMenuOption("personal")}
              onMouseLeave={() => setshowProjectMenuOption("")}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <FormControlLabel
                control={
                  <Radio
                    checked={selectedProject === null}
                    onChange={() => handleRadioChange(null)}
                    color="primary"
                  />
                }
                label={
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "grey",
                      marginRight: "20px",
                    }}
                  >
                    Personal
                  </Typography>
                }
              />
              {showProjectMenuOption === "personal" && (
                <Tooltip title="New Chat">
                  <Button
                    onClick={() => navigate("/")}
                    sx={{
                      minWidth: "auto",
                      padding: 0.5,

                      borderRadius: "50%",
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                    }}
                  >
                    <img src={newchatIcon} width={20} height={20} />
                  </Button>
                </Tooltip>
              )}
            </Box>
            {projects?.length > 0 ? (
              projects?.map((project: any, index: number) => (
                <div
                  onMouseOver={() => setshowProjectMenuOption(index)}
                  onMouseLeave={() => setshowProjectMenuOption("")}
                  key={index}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <FormControlLabel
                    control={
                      <Radio
                        checked={selectedProject === project?.projectId} // Assuming each project has a unique `id`
                        onChange={() => handleRadioChange(project?.projectId)}
                        value={project?.projectId}
                        color="primary"
                      />
                    }
                    label={
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: "grey",
                          marginRight: "20px",
                        }}
                      >
                        {project?.projectName}
                      </Typography>
                    }
                  />
                  {showProjectMenuOption !== "" &&
                    showProjectMenuOption == index && (
                      <div>
                        <Tooltip title="New Chat">
                          <Button
                            onClick={() => navigate("/")}
                            sx={{
                              minWidth: "auto",
                              padding: 0.5,

                              borderRadius: "50%",
                              "&:hover": {
                                backgroundColor: "transparent",
                              },
                            }}
                          >
                            {/* <LibraryAddRoundedIcon fontSize="small" /> */}
                            <img src={newchatIcon} width={20} height={20} />
                          </Button>
                        </Tooltip>
                        <Tooltip title="Edit Project">
                          <Button
                            onClick={() =>
                              setEditEmail({ open: true, editData: project })
                            }
                            sx={{
                              minWidth: "auto",
                              padding: 0.5,

                              borderRadius: "50%",
                              "&:hover": {
                                backgroundColor: "transparent",
                              },
                            }}
                          >
                            <EditRoundedIcon fontSize="small" />
                          </Button>
                        </Tooltip>
                        <Tooltip title="Delete Project">
                          <Button
                            onClick={() => {
                              setConfirmModalMsg({
                                title: "Delete Project",
                                caption:
                                  "Are you sure you want to delete this Project",
                                project,
                              });
                              handleOpenModal();
                            }}
                            sx={{
                              minWidth: "auto",
                              padding: 0.5,
                              borderRadius: "50%",
                              "&:hover": {
                                backgroundColor: "transparent",
                              },
                            }}
                          >
                            <DeleteForeverRoundedIcon fontSize="small" />
                          </Button>
                        </Tooltip>
                      </div>
                    )}
                </div>
              ))
            ) : (
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "grey", // Replace with your actual color or variable
                  marginRight: "20px",
                }}
              >
                You can add a team member by simply clicking the ‘Plus’ sign.
              </Typography>
            )}
          </Box>
        </Box>
      </Stack>
      <Stack
        style={{
          background: serviceDescriptionCards,
        }}
        className="leftCart"
      >
        <Box sx={{ width: "100%" }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Button
              sx={{
                borderRadius: "50%",
                minWidth: "auto",
                boxShadow: "none",
                paddingLeft: "0px",
              }}
            >
              <img src={savedIcon} alt="bounce-rate-icon" />
            </Button>
            <Typography color={customBlack} fontWeight="600" fontSize={"18px"}>
              Saved Patents
            </Typography>
          </Box>
          <Box
            sx={{
              maxHeight: "200px",
              overflowY: "auto",
              boxSizing: "border-box",
              borderWidth: "1px",
              borderColor: "#ccc",
              // scrollbarWidth: "thin",
              scrollbarWidth: "none", // Hide scrollbar by default for Firefox
              "&:hover": {
                scrollbarWidth: "thin", // Show scrollbar on hover for Firefox
                "&::-webkit-scrollbar": {
                  width: "5px", // Show thin scrollbar on hover for WebKit browsers
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#ccc", // Customize scrollbar thumb color on hover
                  borderRadius: "5px",
                },
              },
              "&::-webkit-scrollbar": {
                width: "0px", // Initially hide scrollbar for WebKit browsers
              },
            }}
          >
            {bookMark?.length > 0 ? (
              bookMark?.map((desc: any, index: any) => {
                console.log("🚀 ~ bookMark?.map ~ desc:", desc);
                return (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      position: "relative",
                      justifyContent: "space-between",
                      // borderBottom: "1px solid #d3d3d394",
                    }}
                  >
                    <Typography
                      onClick={() => {
                        setopenPatentModal(true);
                        setmodalPatentDetail(desc);
                      }}
                      key={index}
                      sx={{
                        fontSize: "14px",
                        color: lightGray,
                        marginRight: "20px",
                        padding: "10px 0",
                        cursor: "pointer",
                      }}
                    >
                      {/* {desc?.result?.title && desc?.result?.title?.length > 35
                        ? desc?.result?.title.slice(0, 35) + "..."
                        : desc?.result?.title} */}
                      {desc?.result?.title &&
                        capitalizeFirstLetter(desc.result.title.toLowerCase())}
                    </Typography>
                    <Button
                      id={`basic-button ${desc?.patent_no}`}
                      aria-controls={
                        bookmarkopenOption ? "basic-menu" : undefined
                      }
                      aria-haspopup="true"
                      aria-expanded={bookmarkopenOption ? "true" : undefined}
                      onClick={(e) => handleClick(e, desc, index)}
                      sx={{
                        borderRadius: "50%",
                        width: "40px",
                        height: "40px",
                        minWidth: "auto",
                        boxShadow: "none",
                      }}
                    >
                      <MoreVertSharpIcon
                        sx={{ color: "#808080", fontSize: "18px" }}
                      />
                    </Button>
                    {bookmarkId === desc.bookmark_id && (
                      // <div
                      //   style={{
                      //     position: "absolute",
                      //     background: "white",
                      //     right: "0px",
                      //     padding: "5px",
                      //     top: "0px",
                      //     cursor: "pointer",
                      //     boxShadow: "0px 0px 4px 0px black",
                      //     borderRadius: "5px",
                      //   }}
                      // >
                      //   <Button onClick={() => handleBookmark(desc)}>
                      //     Delete
                      //   </Button>
                      // </div>
                      <Menu
                        id={`basic-button`}
                        anchorEl={bookmarkanchorEl}
                        open={bookmarkopenOption}
                        onClose={handleCloseOption}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                        PaperProps={{
                          sx: { boxShadow: "3px 4px 12px -2px lightgray" }, // Apply shadow to the Paper component
                        }}
                      >
                        <MenuItem
                          // onClick={() => handleBookmark(desc)}
                          onClick={() => {
                            setConfirmModalMsg({
                              title: "Delete Bookmark",
                              caption:
                                "Are you sure you want to delete this Bookmark",
                              desc,
                            });
                            handleOpenModal();
                          }}
                        >
                          Delete
                        </MenuItem>
                      </Menu>
                    )}
                    {/* <Menu
                        id={`basic-button ${desc?.patent_no}`}
                      anchorEl={anchorEl}
                      open={openOption}
                      onClose={handleCloseOption}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                      PaperProps={{
                        sx: { boxShadow: "3px 4px 12px -2px lightgray" }, // Apply shadow to the Paper component
                      }}
                    >
                      <MenuItem
                        onClick={() => {
                          handleBookmark(desc);
                        }}
                      >
                        Delete {desc.patent_no}
                      </MenuItem>
                    </Menu> */}
                  </Box>
                );
              })
            ) : (
              <Typography
                sx={{
                  fontSize: "14px",
                  color: lightGray,
                  marginRight: "20px",
                  padding: "10px 0",
                  cursor: "pointer",
                }}
              >
                No Saved Patents Yet
              </Typography>
            )}
          </Box>
        </Box>
      </Stack>

      <Stack
        style={{
          background: serviceDescriptionCards,
        }}
        className="leftCart"
      >
        <Box sx={{ width: "100%" }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Button
              sx={{
                borderRadius: "50%",
                minWidth: "auto",
                boxShadow: "none",
                paddingLeft: "0px",
              }}
            >
              <img src={searchIcon} alt="bounce-rate-icon" />
            </Button>
            <Typography color={customBlack} fontSize={"18px"} fontWeight="600">
              Search History
            </Typography>
          </Box>

          <Box
            sx={{
              maxHeight: "200px",
              overflowY: "auto",
              boxSizing: "border-box",
              borderWidth: "1px",
              borderColor: "#ccc",
              // scrollbarWidth: "thin",
              scrollbarWidth: "none", // Hide scrollbar by default for Firefox
              "&:hover": {
                scrollbarWidth: "thin", // Show scrollbar on hover for Firefox
                "&::-webkit-scrollbar": {
                  width: "5px", // Show thin scrollbar on hover for WebKit browsers
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#ccc", // Customize scrollbar thumb color on hover
                  borderRadius: "5px",
                },
              },
              "&::-webkit-scrollbar": {
                width: "0px", // Initially hide scrollbar for WebKit browsers
              },
            }}
          >
            {searchHistory?.length > 0 ? (
              searchHistory?.map((desc: any, index: any) => {
                console.log("ccccccccccccccccccccccccccc", desc);
                return (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      borderBottom: "1px solid #d3d3d394",
                    }}
                  >
                    <Typography
                      onClick={() => navigate(`/c/${desc.conversation_id}`)}
                      key={index}
                      sx={{
                        fontSize: "14px",
                        color: lightGray,
                        marginRight: "20px",

                        padding: "10px 0",
                        cursor: "pointer",
                      }}
                    >
                      {desc?.title}
                    </Typography>
                    <Button
                      id="basic-button"
                      aria-controls={openOption ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={openOption ? "true" : undefined}
                      // onClick={handleClick}
                      onClick={(e) => handleHistoryMenuClick(e, desc, index)}
                      sx={{
                        borderRadius: "50%",
                        width: "40px",
                        height: "40px",
                        minWidth: "auto",
                        boxShadow: "none",
                      }}
                    >
                      <div onClick={() => setsearchHistoryMenu(desc)}>
                        <MoreVertSharpIcon
                          sx={{ color: "#808080", fontSize: "18px" }}
                        />
                      </div>
                    </Button>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={openOption}
                      onClose={handleClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                      PaperProps={{
                        sx: { boxShadow: "3px 4px 12px -2px lightgray" }, // Apply shadow to the Paper component
                      }}
                    >
                      <MenuItem
                        // sx={{ boxShadow: "3px 4px 12px -2px lightgray" }}
                        // onClick={() =>
                        //   handleDeleteHistory(
                        //     searchHistoryMenu?.conversation_id
                        //   )
                        // }

                        onClick={() => {
                          setConfirmModalMsg({
                            title: "Delete History",
                            caption:
                              "Are you sure you want to delete this History",
                            history: searchHistoryMenu?.conversation_id,
                          });
                          handleOpenModal();
                        }}
                      >
                        Delete
                      </MenuItem>
                    </Menu>
                  </Box>
                );
              })
            ) : (
              <Typography
                sx={{
                  fontSize: "14px",
                  color: lightGray,
                  marginRight: "20px",
                  padding: "10px 0",
                  cursor: "pointer",
                }}
              >
                No Search History Yet
              </Typography>
            )}
          </Box>
        </Box>
      </Stack>
      {/* Tips pannel */}

      <ProjectModal
        open={open}
        handleClose={handleClose}
        GetProject={GetProject}
        editEmail={editEmail}
        setEditEmail={setEditEmail}
      />
      <Modal open={openPatentModal} onClose={handleClosePatentModal}>
        <PatentDetailsModal
          results={modalPatentDetail}
          handleClose={handleClosePatentModal}
        />
      </Modal>
      <ConfirmationModal
        open={openConfirmModal}
        confirmModalMsg={confirmModalMsg}
        onConfirm={handleConfirm}
        onClose={handleCloseModal}
      />
    </Stack>
  );
};

// {showTip && (
//   <Card
//     sx={{
//       backgroundColor: "white",
//       // padding: "16px",
//       borderRadius: "8px",
//       display: "flex",
//       justifyContent: "space-between",
//       alignItems: "center",
//       boxShadow: 1, // Optional: for a slight shadow effect
//       // height: "52px",
//       padding: "20px",
//     }}
//   >
//     <Box
//       sx={{
//         display: "flex",
//         alignItems: "center",
//         gap: "8px", // Space between icon and text
//         height: "100%", // Ensure it takes the full height of the Card
//         paddingLeft: "8px",
//       }}
//     >
//       {/* <HelpIcon sx={{ color: 'purple', fontSize: '24px' }} /> */}
//       <Box sx={{ marginBottom: "15px" }}>
//         <img src={quesMark} />
//       </Box>
//       <Box>
//         <Typography variant="body1" color="purple">
//           Tips
//         </Typography>
//         <Typography variant="caption" color="textSecondary">
//           You can save relevent patents which will show up in the saved
//           patent list!
//         </Typography>
//       </Box>
//     </Box>
//     <IconButton
//       onClick={() => setshowTip(!showTip)}
//       sx={{
//         color: "text.secondary",
//         "&:hover": {
//           color: "text.primary",
//         },
//       }}
//     >
//       <CloseIcon />
//     </IconButton>
//   </Card>
// )}
