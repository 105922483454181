import React, { useEffect, useState } from "react";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  GlobalStyles,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Skeleton,
  Snackbar,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import {
  backgroundColor,
  rightPanelColor,
  leftPnaleColor,
} from "../assets/colors";
import { LeftPanel } from "../components/left-panel";
import Header from "../components/Header";
import TemporaryDrawer from "../components/responsive-left-panel";
import leftLayout from "../assets/icons/layout-sidebar.svg";
import axios from "axios";
import {
  CardElement,
  useStripe,
  useElements,
  Elements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { apiCall } from "../utils/httpClient";
import { CheckCircle } from "@mui/icons-material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { GLOBAL_URL } from "../utils/constants";
import {
  NotificationManager,
  // @ts-ignore
} from "react-notifications";
import toast from "react-hot-toast";
const stripePromise = loadStripe(
  "pk_test_51Q5la2I8udiT4suPCinad4OwizNWsFI4GfEeIgWf6LxRe0LXrkTgbQbBlTlbsrLrnnnvuCQGTojZhgkB55VlAAE300jBujmx3m"
);
interface PaymentFormProps {
  setDialogOpen: (data: any) => void;
  dialogOpen: any;
  planId: any;
  getUserSubscription: (data: any) => void;
}

const PaymentForm: React.FC<PaymentFormProps> = ({
  dialogOpen,
  setDialogOpen,
  planId,
  getUserSubscription,
}: any) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState<string | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [paymentprocessing, setPaymentProccessing] = useState(false);
  const navigate = useNavigate();

  const cardStyle = {
    base: {
      color: "#000",
      fontSize: "16px",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      letterSpacing: "0.025em",
      lineHeight: "24px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);
    if (!cardElement) return;
    setPaymentProccessing(true);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      setError(error.message || "An error occurred.");
      toast.error("Payment failed!");
      setTimeout(() => {
        setDialogOpen(false);
      }, 3000);
    } else {
      setError(null);
      console.log("Payment Method:", paymentMethod);
      await handlePayment();
      setTimeout(() => {
        getUserSubscription();
        navigate("/plans");
        setDialogOpen(false);
      }, 1500);
      toast.success("Payment successfull!");
    }
  };

  const handlePayment = async () => {
    const user_id = await localStorage.getItem("user_id");
    const params = {
      plan_id: planId,
      user_id: user_id,
    };
    try {
      const response = await fetch(`${GLOBAL_URL}/api/plan/createPayment`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(params),
      });
      const responseData = await response.json();
      console.log(
        "🚀 ~ mmmmmm MM handlePayment ~ responseData:",
        responseData.clientSecret
      );

      // Destructure clientSecret from response.data
      // const { clientSecret }: { clientSecret: string } = responseData.clientSecret;
      // console.log("🚀 ~ mmmmmm MM  handlePayment ~ clientSecret:", clientSecret)
      const clientSecret: string = responseData.clientSecret;
      console.log("🚀 ~ mmmmmm MM handlePayment ~ clientSecret:", clientSecret);
      // Check if elements and stripe are initialized
      if (!elements || !stripe) {
        setError("Stripe is not initialized. Please try again.");
        return;
      }

      const cardElement = elements.getElement(CardElement);

      // Check if cardElement is initialized
      if (!cardElement) {
        setError("Card element is not initialized. Please try again.");
        return;
      }

      const { error: paymentError, paymentIntent } =
        await stripe.confirmCardPayment(clientSecret, {
          payment_method: {
            card: cardElement,
          },
        });

      if (paymentError) {
        console.log("🚀 ~ mmmmmm handlePayment ~ paymentError:", paymentError);
      } else if (paymentIntent && paymentIntent.status === "succeeded") {
        const paymentIntent_id = paymentIntent.id;
        console.log("mmmmmm Payment successful!", paymentIntent);
        createSubs(paymentIntent_id);

        // Optionally handle success (e.g., update UI, navigate, etc.)
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        // Axios error handling
        setError(
          `Payment failed: ${
            error.response.data.message || "Please try again."
          }`
        );
      } else {
        setError("Payment failed. Please try again.");
      }
    }
  };

  const createSubs = async (paymentIntent_id: any) => {
    const user_id = await localStorage.getItem("user_id");
    const params = {
      plan_id: planId,
      user_id: user_id,
      successPayment_id: paymentIntent_id,
    };
    try {
      const response = await fetch(
        `${GLOBAL_URL}/api/plan/createSubscription`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(params),
        }
      );
      const responseData = await response.json();
      console.log(
        "🚀 ~ mmmmmm MM handlePayment ~ responseData:",
        responseData
      );
      toast.success(responseData?.message)
      await getUserSubscription();
      setPaymentProccessing(false);
    } catch (error) {
      console.log(error, "====>");
    }
  };

  return (
    <Dialog
      open={dialogOpen}
      onClose={() => setDialogOpen(false)}
      sx={{ background: "none" }}
    >
      <form style={{ width: 400 }}>
        <Box
          component={Paper}
          elevation={3}
          sx={{
            paddingX: 3,
            margin: "auto",
            borderRadius: 2,
            paddingTop: 3,
            paddingBottom: 3,
            textAlign: "center",
          }}
        >
          <DialogTitle sx={{ mb: 3 }}>Enter Card Details</DialogTitle>
          <DialogContent sx={{ backgroundColor: "transparent" }}>
            <Box component="form">
              <Box sx={{ marginBottom: 2 }}>
                <CardElement options={{ style: cardStyle }} />
              </Box>
              {error && <Typography color="error">{error}</Typography>}
              {paymentprocessing && (
                <Typography color="primary">Proccessing....</Typography>
              )}
              <Button
                onClick={(e) => handleSubmit(e)}
                type="submit"
                disabled={!stripe}
                variant="contained"
                color="primary"
                fullWidth
                sx={{ marginTop: 4 }}
              >
                Pay Now
              </Button>
            </Box>
          </DialogContent>

          <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={() => setSnackbarOpen(false)}
          >
            <Alert
              onClose={() => setSnackbarOpen(false)}
              severity={error ? "error" : "success"}
            >
              {error || "Payment processed successfully!"}
            </Alert>
          </Snackbar>
        </Box>
      </form>
    </Dialog>
  );
};

interface PlansModalProps {
  setDialogOpen: (data: any) => void;
  dialogOpen: boolean;
  isAuth: boolean;
  id: any;
}
const PlansModal: React.FC<PlansModalProps> = ({
  setDialogOpen,
  dialogOpen,
  isAuth,
  id,
}: any) => {
  const [plans, setplans] = useState<any>();
  console.log("🚀 ~ plans:", plans);
  const [userSubscription, setUserSubscription] = useState<any>([]);
  console.log("🚀 ~ userSubscription:", userSubscription);
  const [loading, setLoading] = useState<boolean>(true);
  const [planId, setplanId] = useState("");
  console.log("🚀 ~ planId:", planId);
  const navigate = useNavigate();
  useEffect(() => {
    if (id) {
      console.log("🚀 ~ useEffect ~ id:", id);
      setplanId(id);
    }
  }, []);

  const getUserSubscription = async () => {
    const user_id = await localStorage.getItem("user_id");
    const params = {
      user_id: user_id,
    };

    try {
      const response = await apiCall(
        "post",
        `${GLOBAL_URL}/api/plan/subscriptions`,
        params
      );

      console.log("🚀 ~ response in getUserSubscription:", response.data);

      if (response.status === 200) {
        await setUserSubscription(response.data);
        setTimeout(() => {
          setLoading(false);
        }, 1000);

        // console.log(userSubscription.plan_id, plans[0].plan_id);
      } else {
        console.log("Error: Non-200 status code received");
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const GetPlan = () => {
    apiCall("get", `${GLOBAL_URL}/api/plan/getPlans`)
      .then(function (response) {
        console.log("🚀 ~ response:", response.data);
        if (response.status == 200) {
          setplans(response.data);
          getUserSubscription();
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        } else {
          console.log("error");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    GetPlan();
  }, []);
  const openPaymentModal = async (planId: any) => {
    if (isAuth) {
      setDialogOpen(true);
    } else {
      navigate(`/register?id=${planId}`);
    }
  };
  return (
    <div>
      {plans &&
        plans.map((plan: any, index: any) =>
          userSubscription?.length > 0 &&
          userSubscription.some(
            (user_plan: any) => user_plan.plan_id === plan.plan_id
          ) ? (
            loading ? (
              <Skeleton
                variant="rounded"
                width={260}
                height={300}
                sx={{ marginTop: "70px", marginLeft: "45px" }}
              />
            ) : (
              <Card
                key={index}
                sx={{
                  background: "linear-gradient(321deg, #E0DED6, #f4d7ff)",
                  color: "#000",
                  borderRadius: 2,
                  padding: "20px",
                  width: 220,
                  marginTop: "70px",
                  marginLeft: "45px",
                  // textAlign: 'center',
                }}
              >
                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: 24,
                      fontWeight: "bold",
                      marginBottom: 1, // 16px in MUI system
                    }}
                  >
                    {plan ? plan.plan_type : ""}
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: 24,
                      fontWeight: "bold",
                      marginBottom: 1, // 16px in MUI system
                    }}
                  >
                    <CheckCircle color="primary" />
                  </Typography>
                </Stack>
                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="h4"
                    fontWeight={400}
                    sx={{
                      // fontSize: 40,
                      marginBottom: 1, // 8px in MUI system
                    }}
                  >
                    ${plan.price}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 18,
                      // marginBottom: 1, // 8px in MUI system
                    }}
                  >
                    /{plan ? plan.plan_title : ""}
                  </Typography>
                </Stack>
                <Typography
                  variant="body2"
                  sx={{
                    marginBottom: 2, // 24px in MUI system
                  }}
                >
                  {plan.plan_description}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    marginBottom: 2, // 24px in MUI system
                  }}
                >
                  Expires on{" "}
                  {userSubscription
                    ? userSubscription[0].endDate.substr(0, 10)
                    : ""}
                </Typography>
                <Typography
                  sx={{
                    marginTop: 2,
                    textAlign: "center",
                    fontSize: "14px",
                    color: "#CB6CE6",
                  }}
                >
                  FEATURES
                </Typography>
                <Typography
                  sx={{
                    fontSize: "13px",
                    padding: 0,
                    margin: 0,
                    textAlign: "center",
                    marginTop: "15px",
                  }}
                >
                  {plan ? plan.features : ""}
                </Typography>
              </Card>
            )
          ) : loading ? (
            <Skeleton
              variant="rounded"
              width={260}
              height={300}
              sx={{ marginTop: "70px", marginLeft: "45px" }}
            />
          ) : (
            <Card
              key={index}
              sx={{
                background: "linear-gradient(321deg, #E0DED6, #f4d7ff)",
                color: "#000",
                borderRadius: 2,
                padding: "20px",
                width: 220,
                marginTop: "70px",
                marginLeft: "45px",
                // textAlign: 'center',
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontSize: 24,
                  fontWeight: "bold",
                  marginBottom: 1, // 16px in MUI system
                }}
              >
                {plan ? plan.plan_type : ""}
              </Typography>
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h4"
                  fontWeight={400}
                  sx={{
                    // fontSize: 40,
                    marginBottom: 1, // 8px in MUI system
                  }}
                >
                  ${plan ? plan.price : ""}
                </Typography>
                <Typography
                  sx={{
                    fontSize: 18,
                    // marginBottom: 1, // 8px in MUI system
                  }}
                >
                  /{plan ? plan.plan_title : ""}
                </Typography>
              </Stack>
              <Typography
                variant="body2"
                sx={{
                  marginBottom: 2, // 24px in MUI system
                }}
              >
                {plan ? plan.plan_description : ""}
              </Typography>
              <Button
                onClick={() => {
                  openPaymentModal(plan.plan_id);
                  setplanId(plan.plan_id);
                }}
                variant="contained"
                color="primary"
                sx={{
                  color: "#fff",
                  borderRadius: 3,
                  padding: 1.1, // 12px in MUI system
                  width: "100%",
                  marginTop: 1, // 16px in MUI system
                  fontWeight: 600,
                }}
              >
                Pay
              </Button>
              <Typography
                sx={{
                  marginTop: 2,
                  textAlign: "center",
                  fontSize: "14px",
                  color: "#CB6CE6",
                }}
              >
                FEATURES
              </Typography>
              <Typography
                sx={{
                  fontSize: "13px",
                  padding: 0,
                  margin: 0,
                  textAlign: "center",
                  marginTop: "15px",
                }}
              >
                {plan ? plan.features : ""}
              </Typography>
            </Card>
          )
        )}{" "}
      <Elements stripe={stripePromise}>
        <PaymentForm
          getUserSubscription={getUserSubscription}
          planId={planId}
          setDialogOpen={setDialogOpen}
          dialogOpen={dialogOpen}
        />
      </Elements>
    </div>
  );
};

function Plans({ isAuth }: any) {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const [dialogOpen, setDialogOpen] = useState(false); // To control modal open state

  console.log("🚀 ~ App ~ isAuth:", isAuth);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (id) {
      setDialogOpen(true);
    }
  }, []);
  const togglePanel = () => {
    setOpen(!open);
  };
  useEffect(() => {
    if (!isMobile) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [isMobile]);

  const [openTemp, setOpenTemp] = React.useState(false);
  const [selectedProject, setSelectedProject] = useState<any>(null);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpenTemp(newOpen);
  };

  return (
    <div>
      <Box>
        <Header toggleDrawer={toggleDrawer} isAuth={isAuth} />
        <Stack
          direction="row"
          height="100%"
          style={{ minHeight: "100vh", background: backgroundColor }}
          justifyContent={"center"}
          position={"relative"}
          sx={{ marginTop: "-30px" }}
        >
          {open && isAuth && (
            <Stack
              style={{
                width: open ? "30%" : "0%",
                height: "50vh",
                border: "none",
                background: leftPnaleColor,
                transition: "width 0.2s ease",
                borderRight: "1px solid rgb(226 226 226)",
                paddingBottom: "300px",
                overflow: "auto",
                minHeight: "100%",
                marginTop: "65px",
                // position:"fixed",
                // left:0,
                // top: isMobile ? "110px" : "158px"
              }}
            >
              {open && (
                <LeftPanel
                  setOpenTemp={setOpenTemp}
                  selectedProject={selectedProject}
                  setSelectedProject={setSelectedProject}
                />
              )}
            </Stack>
          )}
          <Stack
            style={{
              width: open ? "70%" : isMobile ? "100%" : "80%",
              height: "100%",
              // justifyContent: "center",
              alignItems: "start",
              background: rightPanelColor,
              transition: "width 0.2s ease",
              // position: 'fixed',
              // right: 0,
              // top: "105px",
              // overflow:"auto"
            }}
          >
            <PlansModal
              setDialogOpen={setDialogOpen}
              dialogOpen={dialogOpen}
              isAuth={isAuth}
              id={id}
            />

            {/* <Button variant="contained" onClick={() => setDialogOpen(true)}>
              Open Payment Modal
            </Button> */}
            {/* <PaymentForm/> */}
          </Stack>
          {!isMobile && isAuth && (
            <Button
              sx={{
                position: "absolute",
                left: 30,
                top: 15,
                borderRadius: "50%",
                padding: 0,
                minWidth: "auto",
                boxShadow: "none",
              }}
              onClick={togglePanel}
            >
              <img
                src={leftLayout}
                alt="left-Layout"
                style={{ width: "20px" }}
              />
            </Button>
          )}
        </Stack>
        {isMobile && (
          <TemporaryDrawer
            openTemp={openTemp}
            setOpenTemp={setOpenTemp}
            selectedProject={selectedProject}
            setSelectedProject={setSelectedProject}
          />
        )}
      </Box>
    </div>
  );
}

export default Plans;
