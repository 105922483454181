import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button
} from '@mui/material';

interface ConfirmationModalProps {
  open: boolean;
  confirmModalMsg:any;
  onConfirm:any;
  onClose: () => void;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  open,
  confirmModalMsg,
  onConfirm,
  onClose
}) => {

  return (
    <Dialog open={open} onClose={onClose} >
      <DialogTitle>{confirmModalMsg?.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {confirmModalMsg?.caption}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{padding:2}} >
        <Button onClick={onClose} color="secondary" variant='outlined' >
          Cancel
        </Button>
        <Button onClick={()=>onConfirm(confirmModalMsg)} color="secondary" variant='outlined'>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationModal;
