import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  GlobalStyles,
  Link,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  backgroundColor,
  rightPanelColor,
  leftPnaleColor,
} from "./assets/colors";
import { LeftPanel } from "./components/left-panel";
import { RightPanel } from "./components/right-panel";
import Header from "./components/Header";
import TemporaryDrawer from "./components/responsive-left-panel";
import leftLayout from "./assets/icons/layout-sidebar.svg";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import CustomDialog from "./components/CustomDialog";
import { useNavigate } from "react-router-dom";
import { GLOBAL_URL } from "./utils/constants";
import axios from "axios";
import { useBookmark } from "./context/BookmarkContext";

// Step 1: Create a theme extending the default theme
const themes = createTheme({
  palette: {
    primary: {
      main: "#CB6CE6", // Your custom primary color
    },
  },
  typography: {
    fontFamily: `'Inter', sans-serif`, // Global font family
  },
});

function App({ isAuth }: any) {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = useState(false);
  const [openTemp, setOpenTemp] = React.useState(false);
  const [notificationData, setNotificationData] = useState([]);
  console.log("🚀 ~ App ~ notificationData:", notificationData);
  const [selectedProject, setSelectedProject] = useState<any>(null);
  console.log("🚀 ~ App ~ selectedProject:", selectedProject)
  const { setBookMark } = useBookmark();

  useEffect(() => {
    if (!isMobile) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [isMobile]);

  useEffect(() => {
    getBookmark();
  }, [selectedProject]);

  const togglePanel = () => {
    setOpen(!open);
  };

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpenTemp(newOpen);
  };

  const GlobalStyle = () => {
    return (
      <GlobalStyles
        styles={{
          /* For Webkit browsers (Chrome, Safari) */
          "*::-webkit-scrollbar": {
            width: "6px", // Thin scrollbar width
            height: "6px", // Thin scrollbar height for horizontal scroll
          },
          "*::-webkit-scrollbar-thumb": {
            // backgroundColor: "#CB6CE6", // Scrollbar thumb color (customized here)
            borderRadius: "10px", // Rounded corners for scrollbar
          },
          "*::-webkit-scrollbar-track": {
            // backgroundColor: "#f0f0f0", // Scrollbar track color
          },

          /* For Firefox (using scrollbar-width property) */
          "*": {
            scrollbarWidth: "thin", // Firefox equivalent of thin scrollbar
            // scrollbarColor: "#CB6CE6 #f0f0f0", // Thumb color and track color for Firefox
          },
        }}
      />
    );
  };

  const getNotification = async () => {
    const user_id = await localStorage.getItem("user_id");
    const params = {
      user_id: user_id,
    };
    try {
      const response = await fetch(`${GLOBAL_URL}/api/notification`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(params),
      });
      const responseData = await response.json();
      console.log("🚀 ~ getNotification ~ responseData:", responseData);
      setNotificationData(responseData.notifications);
    } catch (error) {
      console.log(error, "====>");
    }
  };

  const getBookmark = async () => {
    const user_id = await localStorage.getItem("user_id");
    const bookmarkData = {
      user_id: user_id,
      projectId: selectedProject,
    };

    try {
      const response = await axios.post(
        `${GLOBAL_URL}/api/bookmark/getBookmark`,
        bookmarkData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response, "iioo");

      if (response && response.data) {
        const fetchedBookmarks = response.data;
        // setBookmarks(fetchedBookmarks);
        setBookMark(fetchedBookmarks);
      } else {
        console.error("Failed to retrieve bookmarks.");
      }
    } catch (error) {
      console.error("Error in getBookmark function:", error);
    } finally {
      // setLoading(false);
    }
  };



  return (
    <ThemeProvider theme={themes}>
      <CssBaseline />
      <GlobalStyle />
      <Box>
        <Header
          toggleDrawer={toggleDrawer}
          isAuth={isAuth}
          notificationData={notificationData}
        />
        <Stack
          direction="row"
          height="100%"
          style={{ minHeight: "100vh", background: backgroundColor}}
          justifyContent={"center"}
          position={"relative"}
          sx={{ marginTop: "-30px" }}
        >
          {open && isAuth && (
            <Stack
              style={{
                width: open ? "30%" : "0%",
                height: "100vh",
                border: "none",
                background: leftPnaleColor,
                transition: "width 0.2s ease",
                borderRight: "1px solid rgb(226 226 226)",
                paddingBottom: "210px",
                overflow: "auto",
                minHeight: "100%",
                marginTop: "65px",
                // position:"fixed",
                // left:0,
                // top: isMobile ? "110px" : "158px"
              }}
            >
              {open && (
                <LeftPanel
                  setOpenTemp={setOpenTemp}
                  selectedProject={selectedProject}
                  setSelectedProject={setSelectedProject}
                />
              )}
            </Stack>
          )}
          <Stack
            style={{
              width: open ? "70%" : isMobile ? "100%" : "80%",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              background: rightPanelColor,
              transition: "width 0.2s ease",
              // position: 'fixed',
              // right: 0,
              // top: "105px",
              // overflow:"auto"
            }}
          >
            <RightPanel
            opensidebar={open}
              getNotification={getNotification}
              isAuth={isAuth}
              selectedProject={selectedProject}
              setSelectedProject={setSelectedProject}
              isMobileResponsive={isMobile}
            />
          </Stack>
          {!isMobile && isAuth && (
            <Tooltip title="Toggle Side Panel">

            <Button
              sx={{
                position: "absolute",
                left: 30,
                top: 15,
                borderRadius: "50%",
                padding: 0,
                minWidth: "auto",
                boxShadow: "none",
              }}
              onClick={togglePanel}
            >
              <img
                src={leftLayout}
                alt="left-Layout"
                style={{ width: "20px" }}
              />
            </Button>
            </Tooltip>
          )}
        </Stack>
        {isMobile && (
          <TemporaryDrawer openTemp={openTemp} setOpenTemp={setOpenTemp} 
          selectedProject={selectedProject}
          setSelectedProject={setSelectedProject} />
        )}

        {!isAuth && (
          <Box
            sx={{
              position: "fixed",
              bottom: "0",
              width: "100%",
              textAlign: "center",
              paddingBottom: "10px",
              backgroundColor: "#F3F2EC",
            }}
          >
            <Typography>
              Click here to
              <Button
                onClick={() => navigate("/login")}
                variant="text"
                sx={{
                  fontSize: "1rem",
                  textTransform: "none",
                  padding: 0,
                  "&:hover": {
                    textDecoration: "underline", // Keep underline on hover
                  },
                }}
              >
                Login
              </Button>
              or
              <Button
                onClick={() => navigate("/register")}
                variant="text"
                sx={{
                  fontSize: "1rem",
                  textTransform: "none",
                  "&:hover": {
                    textDecoration: "underline", // Keep underline on hover
                  },
                }}
              >
                sign up
              </Button>
            </Typography>
          </Box>
        )}
      </Box>
    </ThemeProvider>
  );
}

export default App;
